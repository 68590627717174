import * as React from "react";
import { useState, useEffect, useRef, useCallback } from "react";
import { createPublicClient, http, getAddress, verifyMessage } from 'viem';
import type { Address } from 'viem';
import { mainnet } from 'viem/chains';
import { usePresence } from "./presence-context";
import { usePresenceWithCursors } from "./use-cursors";
import { Pointer } from "./cursor";

// Global interface declaration
declare global {
  interface Window {
    ethereum?: any;
  }
}

// Add these new interfaces
interface EIP6963ProviderInfo {
  uuid: string;
  name: string;
  icon: string;
  rdns: string;
}

interface EIP6963AnnounceProviderEvent extends CustomEvent {
  detail: {
    info: EIP6963ProviderInfo;
    provider: import('viem').WalletClient;
  };
}

// Utility functions
function isLightColor(color: string): boolean {
  const hex = color.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness > 200;
}

// beep
function playBeep() {
  const ctx = new AudioContext();
  const osc = ctx.createOscillator();
  const gain = ctx.createGain();

  osc.type = 'triangle';
  osc.frequency.setValueAtTime(
    [440, 494.88, 523.25, 587.33, 659.25, 698.46, 783.99][Math.floor(Math.random() * 7)],
    ctx.currentTime
  );

  gain.gain.setValueAtTime(0.06, ctx.currentTime);

  osc.connect(gain).connect(ctx.destination);
  osc.start();
  osc.stop(ctx.currentTime + 0.03);
}

// Shortcodes
const applyTextReplacements = (text: string): string => {
  const replacements: [string, string][] = [
    ['/@@', '(@ _ @;)'],
    ['/\\?\\?', '¯\\_(ツ)_/¯'],
    ['/cat', 'ฅ^ • ﻌ • ^ฅ'],
    ['/cry', '｡°(°.◜ᯅ◝°)°｡'],
    ['/1337', '(⌐■_■)'],
  ];

  let result = text;
  for (const [shortcut, replacement] of replacements) {
    result = result.replace(new RegExp(shortcut, 'g'), replacement);
  }
  return result;
};

// Styles
const styles: Record<string, React.CSSProperties> = {
  container: {
    boxSizing: "border-box",
    position: "fixed",
    padding: "8px",
    borderRadius: "24px",
    minWidth: "2.5em",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    gap: "8px",
    fontFamily: 'system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    fontWeight: 320,
    zIndex: 1000,
    boxShadow: '0 2px 2px rgba(0,0,0,0.2)',
    pointerEvents: 'none',
  },
  input: {
    boxSizing: "border-box",
    padding: "0 4px",
    margin: 0,
    fontSize: "16px",
    lineHeight: 1,
    whiteSpace: "nowrap",
  },
  button: {
    width: '44px',
    height: '44px',
    borderRadius: '50%',
    border: '2px solid white',
    cursor: 'pointer',
    padding: 0,
    margin: 0,
    overflow: 'hidden',
    position: 'relative',
    boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    boxSizing: 'border-box',
    userSelect: 'none',
    WebkitUserSelect: 'none',
    outline: 'none',
  },
  colorPickerContainer: {
    width: '44px',
    height: '44px',
    position: 'relative',
  },
  colorButton: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    border: '2px solid white',
    cursor: 'pointer',
    padding: 0,
    margin: 0,
    overflow: 'hidden',
    position: 'relative',
    boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
  },
  colorInput: {
    position: 'absolute',
    top: '-5px',
    left: '-5px',
    width: 'calc(100% + 10px)',
    height: 'calc(100% + 10px)',
    border: 'none',
    padding: 0,
    margin: 0,
    cursor: 'pointer',
  },
  chatBubbleContainer: {
    position: "absolute",
    zIndex: 1000,
    pointerEvents: 'none',
  },
  ensNameContainer: {
    position: 'absolute',
    top: '-56px',
    left: '12px',
    fontSize: '10px',
    padding: '4px 5px', 
    borderRadius: '10px',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
  verificationIcon: {
    marginLeft: '4px',
    width: '14px',
    height: '14px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  verificationCheckmark: {
    position: 'absolute',
    fontSize: '10px',
    fontWeight: 'bold',
  },
  simpleVerificationIcon: {
    marginLeft: '4px',
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '8px',
    fontWeight: 'bold',
  },
  controlButtonsContainer: {
    position: 'fixed',
    bottom: '24px',
    right: '32px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    zIndex: 1001,
  },
  walletButton: {
    width: '24px',
    height: '24px',
    borderRadius: '6px',
    border: 'none',
    cursor: 'pointer',
    padding: 0,
    margin: 0,
    overflow: 'hidden',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    transition: 'transform 0.3s ease-out, opacity 0.3s ease-out',
    userSelect: 'none',
    WebkitUserSelect: 'none',
    outline: 'none',
  },
  walletIcon: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    borderRadius: '4px',
  },
  chatBubbleWrapper: {
    position: 'absolute',
    left: '12px',
    top: '-30px',
  },
  walletOptionsWrapper: {
    position: 'relative',
    width: '44px',
    height: '44px',
  },
  walletProviderButton: {
    transition: 'all 0.2s cubic-bezier(0.25, 0.1, 0.25, 1.0)',
  },
};

// Sub-components
const ColorPicker = ({ color, onChange }: { color: string; onChange: (color: string) => void }) => {
  const handleColorPickerClick = () => {
      playBeep();
  };

  return (
      <div style={styles.colorButton}>
          <input
              type="color"
              value={color}
              onClick={handleColorPickerClick}
              onChange={(e) => {
                  onChange(e.target.value);
              }}
              style={styles.colorInput}
          />
      </div>
  );
};

const UserIcon = () => (
  <svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"/>
  </svg>
);

const EyeIcon = ({ isOpen }: { isOpen: boolean }) => (
  <svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
    {isOpen ? (
      <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"/>
    ) : (
      <path d="M3 12h18" strokeWidth="2" stroke="currentColor" fill="none"/>
    )}
  </svg>
);

// Main Chat component
export default function Chat({ isQuietMode, toggleQuietMode }: { isQuietMode: boolean; toggleQuietMode: () => void }) {
  // State declarations
  const [listening, setListening] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [showCursor, setShowCursor] = useState(true);
  const [isMovingRight, setIsMovingRight] = useState(true);
  const [showInput, setShowInput] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [shouldPersist, setShouldPersist] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [ensName, setEnsName] = useState<string | null>(null);
  const [providers, setProviders] = useState<EIP6963AnnounceProviderEvent['detail'][]>([]);
  const [showWalletOptions, setShowWalletOptions] = useState(false);
  const [animateWalletOptions, setAnimateWalletOptions] = useState(false);
  const walletOptionsRef = useRef<HTMLDivElement>(null);
  const [expandComplete, setExpandComplete] = useState(false);

  // Hooks
  const { updatePresence, showCTA, myself, myId } = usePresence((state) => ({
    updatePresence: state.updatePresence,
    showCTA: Array.from(state.otherUsers.values()).some(user => user.presence?.message),
    myself: state.myself,
    myId: state.myId,
  }));

  const myCursor = usePresenceWithCursors((state) => state.myself?.presence.cursor);
  const prevCursorRef = useRef(myCursor);

  // Derived values
  const svgIndex = (myId?.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0) ?? 0) % 5;
  const currentColor = myself?.presence.color ?? "#000000";
  const isLight = isLightColor(currentColor);

  // Effects
  useEffect(() => {
    if (myCursor && prevCursorRef.current) {
      if (myCursor.x !== prevCursorRef.current.x) {
        setIsMovingRight(myCursor.x > prevCursorRef.current.x);
      }
    }
    prevCursorRef.current = myCursor;
  }, [myCursor]);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowCursor(prev => !prev);
    }, 500);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    updatePresence({ 
      message: message.length > 0 ? message : null,
      ensName: ensName,
    });
  }, [message, ensName, updatePresence]);

  useEffect(() => {
    const resetTimeout = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      if (!shouldPersist) {
        const newTimeoutId = setTimeout(() => {
          setListening(false);
          setMessage("");
          setShowInput(false);
        }, 10000);
        setTimeoutId(newTimeoutId);
      } else {
        const newTimeoutId = setTimeout(() => {
          setShouldPersist(false);
        }, 300000);
        setTimeoutId(newTimeoutId);
      }
    };

    if (message || listening) {
      resetTimeout();
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [message, listening, shouldPersist]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!listening) {
        if (event.key === "/") {
          setMessage("");
          setListening(true);
          setShowInput(true);
          setShouldPersist(false);
          event.preventDefault();
        } else if (event.key === "Escape" && message) {
          setMessage("");
          setShowInput(false);
          setShouldPersist(false);
          event.preventDefault();
        }
      } else {
        if (!event.metaKey && !event.ctrlKey && !event.altKey) {
          if (event.key === "Enter") {
            setListening(false);
            setShouldPersist(true);
          } else if (event.key === "Escape") {
            setListening(false);
            setMessage("");
            setShowInput(false);
            setShouldPersist(false);
          } else if (event.key === "Backspace") {
            setMessage((prev) => prev.slice(0, -1));
          } else if (event.key.length === 1) {
            setMessage((prev) => {
              const newText = prev + event.key;
              return applyTextReplacements(newText).slice(0, 64);
            });
          }

          event.preventDefault();
          event.stopPropagation();
          return false;
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [listening, message]);

  // Helper functions
  const handleColorChange = (color: string) => {
    updatePresence({ color });
  };

  const getEIP6963Providers = (): Promise<EIP6963AnnounceProviderEvent['detail'][]> => {
    return new Promise((resolve) => {
      const providers: EIP6963AnnounceProviderEvent['detail'][] = [];
      
      const handleAnnounce = (event: EIP6963AnnounceProviderEvent) => {
        providers.push(event.detail);
      };

      window.addEventListener('eip6963:announceProvider', handleAnnounce as EventListener);

      // Dispatch the event after setting up the listener
      window.dispatchEvent(new Event('eip6963:requestProvider'));

      setTimeout(() => {
        window.removeEventListener('eip6963:announceProvider', handleAnnounce as EventListener);
        resolve(providers);
      }, 100);
    });
  };

  const connectWallet = async () => {
    try {
      const detectedProviders = await getEIP6963Providers();
      if (detectedProviders.length === 0) {
        alert('Please install an ENS compatible signing extension to display your ENS or DNS name in chat.');
        return;
      }
      setProviders(detectedProviders);
      if (detectedProviders.length === 1) {
        // If only one provider, connect directly
        handleWalletButtonClick(detectedProviders[0].provider);
      } else {
        // If multiple providers, show options
        setShowWalletOptions(true);
        // Trigger animation after a short delay to ensure DOM update
        setTimeout(() => setAnimateWalletOptions(true), 50);
      }
    } catch (error) {
      alert('Failed to connect signer extension: ' + (error instanceof Error ? error.message : String(error)));
    }
  };

  const handleWalletButtonClick = async (provider: any) => {
    try {
      // Close wallet options immediately
      setAnimateWalletOptions(false);
      setTimeout(() => setShowWalletOptions(false), 300);

      // Request accounts
      const accounts = await provider.request({ method: 'eth_requestAccounts' });
      const address = getAddress(accounts[0] as Address);

      // Create client and resolve ENS name in parallel
      const client = createPublicClient({
        chain: mainnet,
        transport: http(),
      });

      const [, ensName] = await Promise.all([
        client,
        client.getEnsName({ address })
      ]);

      if (!ensName) {
        alert('No ENS/DNS name found for your address. Please set up an ENS name to use this feature.');
        return;
      }

      const message = `Verify ENS/DNS ownership to display ${ensName} as your name in chat ฅ^•ﻌ•^ฅ\n\nThis homepage will never trigger any blockchain transactions and no personal information is stored.`;

      const signature = await provider.request({
        method: 'personal_sign',
        params: [message, address, ''],
      });

      const recoveredAddress = await verifyMessage({
        address,
        message,
        signature,
      });

      if (recoveredAddress === true) {
        setEnsName(ensName);
        setIsConnected(true);
        updatePresence({ ensName: ensName });
      } else {
        alert('Failed to verify ENS/DNS ownership');
      }
    } catch (error) {
      alert('Failed to connect signer extension: ' + (error instanceof Error ? error.message : String(error)));
    }
  };

  const closeWalletOptions = useCallback(() => {
    setAnimateWalletOptions(false);
    setTimeout(() => setShowWalletOptions(false), 300);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (walletOptionsRef.current && !walletOptionsRef.current.contains(event.target as Node)) {
        closeWalletOptions();
      }
    };

    if (showWalletOptions) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showWalletOptions, closeWalletOptions]);

  useEffect(() => {
    if (!showWalletOptions) {
      setAnimateWalletOptions(false);
      // Add a small delay before clearing providers to allow for exit animation
      const timeout = setTimeout(() => setProviders([]), 300);
      return () => clearTimeout(timeout);
    }
  }, [showWalletOptions]);

  useEffect(() => {
    if (animateWalletOptions) {
      setTimeout(() => setExpandComplete(true), 200);
    } else {
      setExpandComplete(false);
    }
  }, [animateWalletOptions]);

  const renderChatContent = () => {
    if (message) {
      return message;
    } else if (listening) {
      return showCursor ? "..." : "\u00A0".repeat(3);
    } else if (showCTA && !isQuietMode) {
      return "type / to reply";
    } else {
      return null;
    }
  };

  // Render
  return (
    <>
      {(showInput || message || (showCTA && !isQuietMode)) && myCursor && (
        <div
          style={{
            ...styles.chatBubbleContainer,
            left: `${myCursor.x}px`,
            top: `${myCursor.y}px`,
            pointerEvents: message || listening ? 'none' : 'none', // Add this line
          }}
        >
          <div style={{
            transform: isMovingRight ? 'scaleX(-1)' : 'none'
          }}>
            <Pointer fill={currentColor} svgIndex={svgIndex} />
          </div>
          {myself?.presence.ensName && (
            <div style={{
              ...styles.ensNameContainer,
              color: isLight ? "black" : "white",
              backgroundColor: currentColor,
            }}>
              <span>{myself.presence.ensName}</span>
              {myself.presence.ensName === 'elle.email' ? (
                <span style={styles.verificationIcon}>
                  <svg viewBox="0 0 294.996 294.996" width="14" height="14">
                    <path d="M280.977,118.478c-13.619-10.807-20.563-27.57-18.574-44.845c1.3-11.3-2.566-22.393-10.607-30.432
                    c-8.044-8.043-19.136-11.909-30.434-10.607c-17.281,1.986-34.037-4.954-44.844-18.573C169.449,5.11,158.872,0,147.499,0
                    c-11.374,0-21.951,5.11-29.021,14.02c-10.807,13.618-27.564,20.56-44.841,18.575c-11.3-1.305-22.393,2.563-30.435,10.605
                    c-8.043,8.04-11.909,19.133-10.609,30.435c1.989,17.272-4.954,34.035-18.576,44.844C5.11,125.549,0,136.126,0,147.498
                    s5.109,21.949,14.019,29.021c13.62,10.808,20.563,27.57,18.574,44.845c-1.3,11.3,2.566,22.393,10.607,30.432
                    c8.044,8.043,19.145,11.911,30.434,10.607c17.274-1.988,34.037,4.954,44.844,18.573c7.069,8.91,17.646,14.021,29.021,14.021
                    c11.373,0,21.95-5.11,29.02-14.02c10.808-13.618,27.565-20.559,44.841-18.575c11.301,1.299,22.393-2.563,30.435-10.605
                    c8.043-8.04,11.909-19.133,10.609-30.434c-1.989-17.273,4.955-34.037,18.576-44.845c8.907-7.07,14.017-17.647,14.017-29.02
                    S289.886,125.549,280.977,118.478z" fill={isLight ? "black" : "white"} />
                  </svg>
                  <span style={{
                    ...styles.verificationCheckmark,
                    color: currentColor,
                  }}>
                    ✓
                  </span>
                </span>
              ) : (
                <span style={{
                  ...styles.simpleVerificationIcon,
                  backgroundColor: isLight ? "black" : "white",
                  color: currentColor,
                }}>
                  ✓
                </span>
              )}
            </div>
          )}
          <div style={{ 
            ...styles.container, 
            ...styles.chatBubbleWrapper,
            backgroundColor: currentColor,
            color: isLight ? "black" : "white",
            pointerEvents: message || listening ? 'none' : 'none',
          }}>
            <div style={styles.input}>
              {renderChatContent()}
            </div>
          </div>
        </div>
      )}
      {(showInput || message) && (
        <div style={{
          ...styles.controlButtonsContainer,
          pointerEvents: 'auto',
        }}>
          <div style={styles.colorPickerContainer}>
            <ColorPicker
              color={currentColor}
              onChange={handleColorChange}
            />
          </div>
          <button
            style={{
              ...styles.button,
              backgroundColor: isQuietMode ? '#000' : '#ccc',
            }}
            onClick={() => {
              toggleQuietMode();
              playBeep();
            }}
          >
            <EyeIcon isOpen={!isQuietMode} />
          </button>
          <div style={styles.walletOptionsWrapper} ref={walletOptionsRef}>
            {providers.map((provider, index) => (
              <button
                key={provider.info.uuid}
                style={{
                  ...styles.walletButton,
                  ...styles.walletProviderButton,
                  opacity: animateWalletOptions ? 1 : 0,
                  transform: animateWalletOptions 
                    ? `translateY(${expandComplete ? -35 - 35 * index : -40 - 40 * index}px) translateX(10px)` 
                    : 'translateY(0) translateX(10px)',
                  zIndex: providers.length - index,
                }}
                onClick={() => {
                  playBeep();
                  handleWalletButtonClick(provider.provider);
                }}
              >
                <img 
                  src={provider.info.icon} 
                  alt={provider.info.name} 
                  style={styles.walletIcon}
                />
              </button>
            ))}
            <button 
              style={{
                ...styles.button,
                backgroundColor: isConnected ? '#000' : '#ccc',
                zIndex: providers.length + 1,
              }} 
              onClick={() => {
                connectWallet();
                playBeep();
              }}
            >
              <UserIcon />
            </button>
          </div>
        </div>
      )}
    </>
  );
}
