import * as React from "react";
import { render } from "react-dom";
import PresenceProvider from "./presence/presence-context";
import Cursors from "./presence/Cursors";

declare const PARTYKIT_HOST: string;

const pageId = window?.location.href
  ? btoa(window.location.href.split(/[?#]/)[0])
  : "default";

function generateRandomColor(): string {
  return '#' + Math.floor(Math.random()*16777215).toString(16).padStart(6, '0');
}

function App() {
  const randomColor = React.useMemo(() => generateRandomColor(), []);

  return (
    <PresenceProvider
      host={PARTYKIT_HOST}
      room={pageId}
      presence={{
        name: "Anonymous User",
        color: randomColor,
      }}
    >
      <Cursors />
    </PresenceProvider>
  );
}

const cursorsRoot = document.createElement("div");
document.body.appendChild(cursorsRoot);
document.documentElement.style.position = "relative";
document.documentElement.style.minHeight = "100dvh";
cursorsRoot.classList.add("cursors-root");

render(<App />, cursorsRoot);
