import * as React from "react";
import type { User } from "./presence-schema";
import { usePresenceWithCursors } from "./use-cursors";

function isLightColor(color: string): boolean {
  const hex = color.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness > 200;
}

const cursorSVGs = [
  // sphere
  <g transform="scale(2)">
    <path stroke="#ffffff" d="M4 0h5M2 1h2M9 1h1M1 2h1M10 2h1M1 3h1M11 3h1M0 4h1M11 4h1M0 5h1M11 5h1M0 6h1M3 6h1M11 6h1M0 7h1M3 7h1M7 7h1M11 7h1M0 8h1M7 8h1M10 8h1M1 9h1M10 9h1M2 10h1M8 10h2M3 11h5" />
    <path stroke="{{fill}}" d="M4 1h5M2 2h8M2 3h9M1 4h10M1 5h10M1 6h1M4 6h7M1 7h1M4 7h2M8 7h3M1 8h5M8 8h2M2 9h8M3 10h5" />
    <path stroke="#323232" d="M2 6h1M2 7h1M6 7h1M6 8h1" />
  </g>,
  // cat
  <g transform="scale(2)">
  <path stroke="#ffffff" d="M3 0h1M8 0h1M2 1h1M4 1h1M7 1h1M9 1h1M1 2h1M5 2h2M10 2h1M1 3h1M10 3h1M0 4h1M10 4h1M0 5h1M11 5h1M0 6h1M3 6h1M11 6h1M0 7h1M3 7h1M7 7h1M11 7h1M0 8h1M7 8h1M11 8h1M1 9h1M10 9h1M2 10h1M8 10h2M3 11h5" />
  <path stroke="{{fill}}" d="M3 1h1M8 1h1M2 2h3M7 2h3M2 3h8M1 4h9M1 5h10M1 6h1M4 6h7M1 7h1M4 7h2M8 7h3M1 8h5M8 8h3M2 9h8M3 10h5" />
  <path stroke="#323232" d="M2 6h1M2 7h1M6 7h1M6 8h1" />
</g>,
  // slime
  <g transform="scale(2)">
  <path stroke="#ffffff" d="M5 0h2M3 1h2M7 1h2M2 2h1M9 2h1M1 3h1M10 3h1M1 4h1M10 4h1M0 5h1M11 5h1M0 6h1M3 6h1M11 6h1M0 7h1M3 7h1M7 7h1M11 7h1M0 8h1M7 8h1M10 8h1M1 9h1M10 9h1M2 10h1M8 10h2M3 11h5" />
  <path stroke="{{fill}}" d="M5 1h2M3 2h6M2 3h8M2 4h8M1 5h10M1 6h1M4 6h7M1 7h1M4 7h2M8 7h3M1 8h5M8 8h2M2 9h8M3 10h5" />
  <path stroke="#323232" d="M2 6h1M2 7h1M6 7h1M6 8h1" />
</g>,
  // cube
  <g transform="scale(2)">
  <path stroke="#ffffff" d="M3 0h7M2 1h1M10 1h1M1 2h1M11 2h1M0 3h1M11 3h1M0 4h1M11 4h1M0 5h1M11 5h1M0 6h1M3 6h1M11 6h1M0 7h1M3 7h1M7 7h1M11 7h1M0 8h1M7 8h1M11 8h1M0 9h1M10 9h1M1 10h1M9 10h1M2 11h7" />
  <path stroke="{{fill}}" d="M3 1h7M2 2h9M1 3h10M1 4h10M1 5h10M1 6h1M4 6h7M1 7h1M4 7h2M8 7h3M1 8h5M8 8h3M1 9h9M2 10h7" />
  <path stroke="#323232" d="M2 6h1M2 7h1M6 7h1M6 8h1" />
</g>,
  // bunny
  <g transform="scale(2)">
  <path stroke="#ffffff" d="M2 0h2M8 0h2M1 1h1M4 1h1M7 1h1M10 1h1M1 2h1M4 2h1M7 2h1M10 2h1M1 3h1M5 3h2M10 3h1M0 4h1M11 4h1M0 5h1M11 5h1M0 6h1M3 6h1M11 6h1M0 7h1M3 7h1M7 7h1M11 7h1M0 8h1M7 8h1M11 8h1M0 9h1M11 9h1M1 10h1M9 10h2M2 11h7" />
  <path stroke="{{fill}}" d="M2 1h2M8 1h2M2 2h2M8 2h2M2 3h3M7 3h3M1 4h10M1 5h10M1 6h1M4 6h7M1 7h1M4 7h2M8 7h3M1 8h5M8 8h3M1 9h10M2 10h7" />
  <path stroke="#323232" d="M2 6h1M2 7h1M6 7h1M6 8h1" />
</g>
];

export function Pointer({ fill, svgIndex }: { fill: string; svgIndex: number }) {
  const svgContent = React.useMemo(() => {
    const svg = cursorSVGs[svgIndex];
    return React.cloneElement(svg, {}, React.Children.map(svg.props.children, child => 
      React.cloneElement(child, { 
        fill: child.props.fill === "{{fill}}" ? fill : child.props.fill,
        stroke: child.props.stroke === "{{fill}}" ? fill : child.props.stroke
      })
    ));
  }, [fill, svgIndex]);

  return (
    <svg
      height="28"
      viewBox="0 0 24 28"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      shapeRendering="crispEdges"
      style={{ filter: 'drop-shadow(0px 2px 2px rgba(0,0,0,0.2))' }}
    >
      <g transform="translate(0, 4)">
        {svgContent}
      </g>
    </svg>
  );
}

export default function Cursor(props: {
  userId: string;
  fill: string;
  showChat: boolean;
}) {
  const user: User | null = usePresenceWithCursors(
    (state) => state.otherUsers.get(props.userId) || null
  );
  const [isMovingRight, setIsMovingRight] = React.useState(false);
  const prevPosition = React.useRef({ x: 0, y: 0 });

  React.useEffect(() => {
    if (user?.presence?.cursor) {
      const currentX = user.presence.cursor.x;
      if (currentX > prevPosition.current.x) {
        setIsMovingRight(true);
      } else if (currentX < prevPosition.current.x) {
        setIsMovingRight(false);
      }
      prevPosition.current = { x: currentX, y: user.presence.cursor.y };
    }
  }, [user?.presence?.cursor]);

  if (!user?.presence?.cursor) return null;

  const cursor = {
    x: user.presence.cursor.x,
    y: user.presence.cursor.y,
    pointer: user.presence.cursor.pointer,
    message: user.presence.message ?? null,
  };

  const isNightMode = !props.showChat;
  const styles = isNightMode
    ? { filter: "blur(1.5px)", opacity: 0.2, zIndex: 1001 }
    : cursor.message !== null
      ? { opacity: 1.0, zIndex: 1001 }
      : { filter: "blur(1px)", opacity: 0.3, zIndex: -1 };

  const cursorColor = user.presence.color || props.fill;
  const isLight = isLightColor(cursorColor);

  const randomSeed = props.userId.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
  const svgIndex = randomSeed % cursorSVGs.length;

  return (
    <div
      key={`${cursor.x}-${cursor.y}`}
      style={{
        position: "absolute",
        transform: `translate(${cursor.x}px, ${cursor.y}px)`,
        ...styles,
      }}
    >
      <div style={{ transform: isMovingRight ? 'scaleX(-1)' : 'none' }}>
        <Pointer fill={cursorColor} svgIndex={svgIndex} />
      </div>
      {cursor.message !== null && (
        <>
          {user.presence.ensName && (
            <div
              style={{
                position: "absolute",
                top: "-56px",
                left: "12px",
                fontSize: '10px',
                color: isLight ? "black" : "white",
                padding: '4px 5px',
                borderRadius: '10px',
                whiteSpace: 'nowrap',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: cursorColor,    
                fontFamily: 'system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'          
            }}>
              <span>{user.presence.ensName}</span>
              {user.presence.ensName === 'elle.email' ? (
                <span style={{
                  marginLeft: '4px',
                  width: '14px',
                  height: '14px',
                  display: 'inline-flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                }}>
                  <svg viewBox="0 0 294.996 294.996" width="14" height="14">
                    <path d="M280.977,118.478c-13.619-10.807-20.563-27.57-18.574-44.845c1.3-11.3-2.566-22.393-10.607-30.432
                    c-8.044-8.043-19.136-11.909-30.434-10.607c-17.281,1.986-34.037-4.954-44.844-18.573C169.449,5.11,158.872,0,147.499,0
                    c-11.374,0-21.951,5.11-29.021,14.02c-10.807,13.618-27.564,20.56-44.841,18.575c-11.3-1.305-22.393,2.563-30.435,10.605
                    c-8.043,8.04-11.909,19.133-10.609,30.435c1.989,17.272-4.954,34.035-18.576,44.844C5.11,125.549,0,136.126,0,147.498
                    s5.109,21.949,14.019,29.021c13.62,10.808,20.563,27.57,18.574,44.845c-1.3,11.3,2.566,22.393,10.607,30.432
                    c8.044,8.043,19.145,11.911,30.434,10.607c17.274-1.988,34.037,4.954,44.844,18.573c7.069,8.91,17.646,14.021,29.021,14.021
                    c11.373,0,21.95-5.11,29.02-14.02c10.808-13.618,27.565-20.559,44.841-18.575c11.301,1.299,22.393-2.563,30.435-10.605
                    c8.043-8.04,11.909-19.133,10.609-30.434c-1.989-17.273,4.955-34.037,18.576-44.845c8.907-7.07,14.017-17.647,14.017-29.02
                    S289.886,125.549,280.977,118.478z" fill={isLight ? "black" : "white"} />
                  </svg>
                  <span style={{
                    position: 'absolute',
                    color: cursorColor,
                    fontSize: '10px',
                    fontWeight: 'bold',
                  }}>
                    ✓
                  </span>
                </span>
              ) : (
                <span style={{
                  marginLeft: '4px',
                  width: '12px',
                  height: '12px',
                  borderRadius: '50%',
                  backgroundColor: isLight ? "black" : "white",
                  display: 'inline-flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '8px',
                  color: cursorColor,
                  fontWeight: 'bold',
                }}>
                  ✓
                </span>
              )}
            </div>
          )}
          <div
            style={{
              position: "absolute",
              fontSize: "16px",
              fontStyle: "normal",
              fontFamily:
                'system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              lineHeight: 1,
              color: isLight ? "black" : "white",
              padding: "8px 12px",
              borderRadius: "24px",
              whiteSpace: "nowrap",
              backgroundColor: cursorColor,
              top: "-30px",
              left: "12px",
              boxShadow: '0 2px 2px rgba(0,0,0,0.2)'
            }}
          >
            {cursor.message}
          </div>
        </>
      )}
    </div>
  );
}
