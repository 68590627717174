import * as React from "react";
import Cursor from "./cursor";
import { usePresenceWithCursors } from "./use-cursors";

// Add this function at the top of the file
function generateColorFromId(id: string): string {
  let hash = 0;
  for (let i = 0; i < id.length; i++) {
    hash = id.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = Math.abs(hash).toString(16).substring(0, 6);
  return '#' + '0'.repeat(6 - color.length) + color;
}

export default function OtherCursors({
  showChat = false,
}: {
  showChat: boolean;
}) {
  const otherUserIds = usePresenceWithCursors((state) =>
    Array.from(state.otherUsers.keys())
  );
  const within = usePresenceWithCursors((state) => state.within);

  const style = {
    position: (within === "window"
      ? "fixed"
      : "absolute") as React.CSSProperties["position"],
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: "clip",
    pointerEvents: "none" as React.CSSProperties["pointerEvents"],
  };

  return (
    <div style={style}>
      {otherUserIds.map((id) => {
        return (
          <Cursor key={id} userId={id} fill={generateColorFromId(id)} showChat={showChat} />
        );
      })}
    </div>
  );
}
