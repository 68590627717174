import * as React from "react";
import useCursorTracking from "./use-cursors";
import OtherCursors from "./other-cursors";
import Chat from "./Chat";

const ENABLE_CHAT = true;

export default function Cursors() {
  const [isChatEnabled, setIsChatEnabled] = React.useState(ENABLE_CHAT);
  const [isNightMode, setIsNightMode] = React.useState(false);
  const [isQuietMode, setIsQuietMode] = React.useState(false);
  const [manualDayMode, setManualDayMode] = React.useState<boolean | null>(null);
  useCursorTracking("document");

  React.useEffect(() => {
    const checkNightMode = () => {
      if (manualDayMode !== null) return; // Don't change if manually set
      const currentHour = new Date().getHours();
      const newNightMode = currentHour >= 22 || currentHour < 6;
      setIsNightMode(newNightMode);
      setIsChatEnabled(ENABLE_CHAT && !newNightMode);
    };

    const handleModeChange = (event: CustomEvent) => {
      const newDayMode = event.detail === 'day';
      setManualDayMode(newDayMode);
      setIsNightMode(!newDayMode);
      setIsChatEnabled(ENABLE_CHAT && newDayMode);
    };

    checkNightMode();
    document.addEventListener('dayNightToggle', handleModeChange as EventListener);

    const interval = setInterval(checkNightMode, 60000); // Check every minute

    return () => {
      clearInterval(interval);
      document.removeEventListener('dayNightToggle', handleModeChange as EventListener);
    };
  }, [manualDayMode]);

  const toggleQuietMode = () => {
    setIsQuietMode(prev => !prev);
  };

  return (
    <>
      {!isQuietMode && <OtherCursors showChat={isChatEnabled} />}
      {isChatEnabled && <Chat isQuietMode={isQuietMode} toggleQuietMode={toggleQuietMode} />}
    </>
  );
}
